import {checkUserStatus} from "../redux/storage/userStorage";
import {useEffect} from "react";
import Container from "@cloudscape-design/components/container";
import Header from "@cloudscape-design/components/header";
import {Grid, Spinner} from "@cloudscape-design/components";
import * as React from "react";
import AutoSubmitForm from "./auto-submit-form";
import {useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";


export default function () {

    const secrets = useSelector(state => state.secrets)
    const site = useSelector(state => state.site)

    let [count, setCount] = useState(0)
    const [submitForm, setSubmitForm] = useState(false)


    const dispatch = useDispatch()
    const countRef = useRef(count);
    countRef.current = count;

    useEffect(() => {
        getCountTimeout()
    })

    const getCountTimeout = () => {
        setTimeout(async () => {
            setCount(++countRef.current);
            if (countRef.current < 25) {
                let resp = await dispatch(checkUserStatus({domain: site.domain, mac: secrets.mac}))
                console.log(resp)
                if (!resp.payload.status) {
                    getCountTimeout()
                } else {
                    //send submit form to mikrotik
                    setSubmitForm(true)
                }
            }
        }, countRef.current === 0 ? 10 : 5000);
    };

    return <Container
        header={<Header variant={`h2`} description={`Please wait as we check for your payment status`}>Validating your
            payment</Header>}>
        <Grid gridDefinition={[{colspan: 4}, {colspan: 4}, {colspan: 4}]}>
            <div></div>
            <div><Spinner size="large" variant={`disabled`}/></div>
            <div></div>
        </Grid>

        {submitForm &&
            <AutoSubmitForm
                actionUrl={site.loginLink}
                params={{
                    "username": secrets.mac,
                    "password": secrets.mac,
                    "dst": 'https://google.com',
                    "popup": "true"
                }}
            />
        }
    </Container>

}
