import * as React from "react";
import TopNavigation from "@cloudscape-design/components/top-navigation";
import {useSelector} from "react-redux";

export default () => {
    const site = useSelector(state => state.site)

    return (
        <TopNavigation
            identity={{
                href: "#",
                title: site.BusinessName,
            }}
            utilities={[
                {
                    type: "button",
                    text: "Call Support",
                    iconName: "call",
                    href: `tel:${site.Phone}`,

                },{
                    type: "button",
                    text: "Email Support",
                    iconName: "envelope",
                    href: `mailto:${site.Email}`,

                },
                // {
                //     type: "menu-dropdown",
                //     text: "Customer Name",
                //     description: "email@example.com",
                //     iconName: "user-profile",
                //     items: [
                //
                //         { id: "signout", text: "Sign out" }
                //     ]
                // }
            ]}
            i18nStrings={{
                searchIconAriaLabel: "Search",
                searchDismissIconAriaLabel: "Close search",
                overflowMenuTriggerText: "More",
                overflowMenuTitleText: "All",
                overflowMenuBackIconAriaLabel: "Back",
                overflowMenuDismissIconAriaLabel: "Close menu"
            }}
        />
    );
}
