import {AppLayout, Header,} from "@cloudscape-design/components";

import {
    Routes,
    Route,
    Link,
    useNavigate,
    useLocation,
    Navigate,
    Outlet,
} from "react-router-dom";
import TopNav from "../components/top-nav";
import MHeader from "../components/m-header";
import Flashbar from "@cloudscape-design/components/flashbar";
import {useSelector} from "react-redux";

export default function Layout() {

    let {customer, status} = useSelector(state => state.customerStatus)
    let warnings = customer && !status ? [{
        type: 'error',
        header: 'Your subscription has expired',
        content: 'You can renew your subscription by clicking the Buy Bundles below',
    }] : []

    return (
        <div>
            <TopNav/>
            <AppLayout
                navigationHide={true}
                toolsHide={true}
                notifications={<Flashbar items={warnings}/>}
                contentHeader={<MHeader/>}
                content={
                    <Outlet/>
                }
            />
        </div>
    );
}
