import {Route, Routes} from "react-router-dom";
import Layout from "./layouts/app-layout";
import LoginRegister from "./pages/login-register";
import Dashboard from "./pages/dashboard";
import PackagesList from "./pages/packages-list";
import PurchasePackage from "./pages/purchase-package";
import RedeemVoucher from "./pages/redeem-voucher";

function App() {
    return (
        <Routes>
            <Route element={<Layout/>}>
                <Route path="/dashboard" element={<Dashboard/>}/>
                <Route path="/list-packages" element={<PackagesList/>}/>
                <Route path="/redeem-voucher" element={<RedeemVoucher/>}/>
                <Route path="/purchase-package/:packageID" element={<PurchasePackage/>}/>
            </Route>
            <Route path="/hs/:domain/:siteIP/:mac" element={<LoginRegister/>}/>
        </Routes>
    );
}

export default App;
