import * as React from "react";
import Container from "@cloudscape-design/components/container";
import ColumnLayout from "@cloudscape-design/components/column-layout";
import Header from "@cloudscape-design/components/header";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Button from "@cloudscape-design/components/button";
import {Alert, Form, FormField, Grid, Input, Spinner, TextContent} from "@cloudscape-design/components";
import {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import parsePhoneNumber from "libphonenumber-js";
import { postPurchaseBundles} from "../../redux/storage/userStorage";
import CheckStatusAndSubmit from "../../components/check-status-and-submit";

export default () => {

    const packages = useSelector(state => state.packages)
    const secrets = useSelector(state => state.secrets)
    const site = useSelector(state => state.site)

    const [showLoadingView, setShowLoadingView] = useState(false)
    const [loading, setIsLoading] = useState(false)
    const [hasError, setHasError] = useState('')
    const [pck, setPck] = useState(null);
    const [inputValue, setInputValue] = useState('')

    const {packageID} = useParams()
    const navigate = useNavigate();
    const dispatch = useDispatch()


    let purchaseBundles = async () => {

        const phoneNumber = parsePhoneNumber(inputValue, 'KE')
        if (!phoneNumber || !phoneNumber.isValid()) {
            setHasError("Incorrect Phone Number")
            return
        }

        if (!secrets.chapID || secrets.chapID === "") {
            setHasError("Incorrect Authentication")
            return
        }

        let data = {
            PhoneNumber: inputValue,
            PackageID: pck.PackageID,
            extra: {mac: secrets.mac, ip: site.siteIP}
        }

        setIsLoading(true)
        let resp = await dispatch(postPurchaseBundles({domain: site.domain, data}))
        setIsLoading(false)
        if (resp.payload.success) {
            setShowLoadingView(true)
        } else {
            setHasError(resp.payload.error)
        }

    }

    useEffect(() => {
        setPck(packages.filter(p => p.PackageID === packageID)[0]);
    }, [packages])

    if (!pck) return <></>

    let PurchaseView = <ColumnLayout disableGutters={true}>
        <Container header={<Header variant="h2">Complete Purchase</Header>}>
            <ColumnLayout>

                <Alert type={`info`} header={pck.title}>
                    Your are about to pay <strong>Ksh {pck.price.amount}</strong> to purchase this
                    package <strong>{pck.title}</strong> for <strong>{pck.price.quantity}</strong>.<br/>
                    Please enter your PIN when prompted to complete the purchase.
                </Alert>

                <ColumnLayout columns={2}>
                    <FormField
                        description="Phone number to send USSD pop-up."
                        label="Phone number">
                        <Input
                            disabled={loading}
                            value={inputValue}
                            type={`number`}
                            min={0}
                            placeholder={`07XXXXXXXX`}
                            onChange={event =>
                                setInputValue(event.detail.value)
                            }/>
                    </FormField>
                </ColumnLayout>

            </ColumnLayout>
        </Container>
        <Form
            actions={
                <SpaceBetween direction="horizontal" size="xs">
                    <Button formAction="none" variant="link" disabled={loading} onClick={() => {
                        navigate(-1)
                    }}>
                        Cancel
                    </Button>
                    <Button variant="primary" loading={loading} onClick={purchaseBundles}>Pay</Button>
                </SpaceBetween>
            }
        >
        </Form>
    </ColumnLayout>

    return (
        <ColumnLayout columns={2}>
            {showLoadingView ? <CheckStatusAndSubmit/> : PurchaseView}
        </ColumnLayout>
    );
}
