import Button from "@cloudscape-design/components/button";
import Header from "@cloudscape-design/components/header";
import SpaceBetween from "@cloudscape-design/components/space-between";
import {useSelector} from "react-redux";
import {useLocation, useNavigate} from "react-router-dom";
import AutoSubmitForm from "./auto-submit-form";
import * as React from "react";
import {useState} from "react";


export default function () {

    const {customer, status} = useSelector(state => state.customerStatus)
    const site = useSelector(state => state.site)
    const secrets = useSelector(state => state.secrets)

    const [statBrowsing, setStatBrowsing] = useState(false)

    let navigate = useNavigate()
    let location = useLocation()

    return <Header
        variant="h3"
        description={`You can always reach us at +${site.Phone}`}
        actions={
            <>
                {
                    customer && <SpaceBetween size={'xl'} direction={'horizontal'}>
                        {status && <Button variant={"normal"} onClick={() => {
                            setStatBrowsing(true)
                        }}>Start Browsing
                        </Button>}

                        {location.pathname === '/dashboard' && !status && <Button variant="primary" onClick={() => {
                            navigate("/list-packages", {replace: false});
                        }}>Buy Bundles
                        </Button>}
                    </SpaceBetween>
                }
            </>
        }
    >

        {statBrowsing &&
            <AutoSubmitForm
                actionUrl={site.loginLink}
                params={{
                    "username": secrets.mac,
                    "password": secrets.mac,
                    "dst": 'https://google.com',
                    "popup": "true"
                }}
            />
        }

        {
            customer && location.pathname !== "/dashboard" &&
            <Button variant="icon" iconName={'arrow-left'} onClick={() => {
                navigate(-1, {replace: true});
            }}>Back
            </Button>
        }
        {/*<br/>*/}
        Need help?
    </Header>

}
