import SpaceBetween from "@cloudscape-design/components/space-between";
import Container from "@cloudscape-design/components/container";
import Header from "@cloudscape-design/components/header";
import Button from "@cloudscape-design/components/button";
import * as React from "react";
import {Form} from "@cloudscape-design/components";


export default function ({name, description, onClick}) {
    return <SpaceBetween size="l">
        <Container>
            <form onSubmit={e => e.preventDefault()}>
                <Form
                    actions={
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button formAction={`none`} variant="primary" onClick={onClick}>Buy</Button>
                        </SpaceBetween>
                    }
                    header={<Header variant="h2">{name}</Header>}
                >{description}
                </Form>
            </form>
        </Container>
    </SpaceBetween>

}
