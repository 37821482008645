import React, {useEffect, useRef} from "react";

const AutoSubmitForm = ({actionUrl, params}) => {
    const formRef = useRef(null);
    useEffect(() => {
        formRef.current.submit();
    }, []);

    return (
        <form ref={formRef} method="POST" action={actionUrl}>
            {Object.keys(params).map(name => (
                <input
                    hidden
                    type="hidden"
                    name={name}
                    value={params[name]}
                />
            ))}
        </form>
    );
};
export default AutoSubmitForm
