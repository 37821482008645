import * as React from "react";
import Container from "@cloudscape-design/components/container";
import ColumnLayout from "@cloudscape-design/components/column-layout";
import Header from "@cloudscape-design/components/header";
import SpaceBetween from "@cloudscape-design/components/space-between";
import StatusIndicator from "@cloudscape-design/components/status-indicator";
import ValueWithLabel from "../../components/value-with-label";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import moment from "moment";
import Button from "@cloudscape-design/components/button";
import {checkUserStatus} from "../../redux/storage/userStorage";

export default () => {

    let [customerPackage, setCustomerPackage] = useState(null);

    const {customer, status} = useSelector(state => state.customerStatus)
    const packages = useSelector(state => state.packages)
    const dispatch = useDispatch()
    const site = useSelector(state => state.site)
    const secrets = useSelector(state => state.secrets)


    useEffect(() => {
        let p = packages.filter(pck => pck.PackageID === customer.PackageId)[0]
        setCustomerPackage(p)
    }, [packages])

    useEffect(() => {
        if (site && secrets)
            dispatch(checkUserStatus({domain: site.domain, mac: secrets.mac}))
    }, [site, secrets, dispatch])


    let name = `${customer.FirstName} ${customer.LastName}`.trim();
    let periodUsage = customer.PeriodUsage.split("/")
    let upload = periodUsage[1]
    let download = periodUsage[0]

    if (!customerPackage) return <></>

    return (
        <Container header={<Header variant="h2">Basic Information </Header>}>

            <ColumnLayout variant="text-grid" columns={3}>
                <SpaceBetween size="l">

                    <ValueWithLabel label={name.length > 0 ? "Name" : "Username"}>
                        <Button iconName="copy" variant="inline-icon"/> {name.length > 0 ? name : customer.Username}
                    </ValueWithLabel>

                    <ValueWithLabel label="Status">
                        <StatusIndicator
                            type={status ? `success` : `error`}>{status ? `Active` : `Expired`}</StatusIndicator>
                    </ValueWithLabel>

                    <ValueWithLabel label="Device">
                        {customer.DeviceType}
                    </ValueWithLabel>

                </SpaceBetween>

                <SpaceBetween size="l">
                    <ValueWithLabel label="Package">
                        {customerPackage.title}
                    </ValueWithLabel>

                    <ValueWithLabel label="Usage">
                        {upload} upload | {download} download
                    </ValueWithLabel>

                    <ValueWithLabel label="Expiry Date">
                        {moment(customer.Expiry).format("HH:mm MMM DD, Y")}
                    </ValueWithLabel>

                </SpaceBetween>
                <SpaceBetween size="l">


                    <ValueWithLabel label="Wallet Balance">
                        Kshs {customer.Balance}
                    </ValueWithLabel>

                </SpaceBetween>

            </ColumnLayout>
        </Container>
    );
}
