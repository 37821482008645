import * as React from "react";
import {Navigate, useLocation} from "react-router-dom";
import {AppLayout, Header} from "@cloudscape-design/components";
import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {addSecrets, checkUserStatus, getSiteData} from "../../redux/storage/userStorage";
import qs from "qs";


export default () => {

    let {domain, siteIP, mac} = useParams();
    let location = useLocation()
    let {chapID, chapChallenge,loginLink} = qs.parse(location.search.replace("?", ""))

    let [nav, setNav] = useState(null);
    const dispatch = useDispatch()


    let fetchUser = async () => {
        let d = await dispatch(checkUserStatus({domain, mac}))
        let {customer} = d.payload
        if (customer)
            setNav('dashboard')
        else
            setNav('list-packages')
    }

    let init = async () => {
        await dispatch(getSiteData({domain, siteIP,loginLink}))
        let secrets = {chapID, chapChallenge, mac}
        await dispatch(addSecrets(secrets))
        await fetchUser()
    }

    useEffect(() => {
        init()
    }, [])

    if (nav) {
        if (nav === 'dashboard')
            return <Navigate to="/dashboard" replace={false}/>
        else if (nav === 'list-packages')
            return <Navigate to="/list-packages" replace={false}/>
    }


    return (
        <AppLayout
            navigationHide={true}
            toolsHide={true}
            content={

                <div>

                </div>
            }/>
    );
}
