import * as React from "react";
import Container from "@cloudscape-design/components/container";
import ColumnLayout from "@cloudscape-design/components/column-layout";
import Header from "@cloudscape-design/components/header";
import Button from "@cloudscape-design/components/button";
import PackageCard from "../../components/package-card";
import {Alert, Tiles} from "@cloudscape-design/components";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {useMemo, useState} from "react";

const _durationGroups = [
    {label: "Daily", value: "daily", min: 0, max: 86_400},
    {label: "Weekly", value: "weekly", min: 86_400, max: 604_800},
    {label: "Monthly", value: "monthly", min: 604_800, max: 1_000_000_000}
]

export default () => {
    const packages = useSelector(state => state.packages)
    const navigate = useNavigate();
    const [durationGroup, setDurationGroup] = useState(_durationGroups[0].value);
    const kvDurations = useMemo(() => {
        let m = {}
        _durationGroups.forEach((d) => {
            m[d.value] = d
        })
        return m
    }, [_durationGroups])

    return (
        <Container variant={'stacked'} header={<Header variant="h2">Purchase Bundles</Header>}>
            <ColumnLayout>
                <Container disableContentPaddings={true}>
                    <Alert
                        type="success"
                        dismissAriaLabel="Close alert"
                        action={<Button onClick={() => {
                            navigate(`/redeem-voucher`, {replace: false});
                        }}>Redeem Voucher</Button>}
                        header="Voucher Card"
                    > Redeem your voucher code to get connected.
                    </Alert>
                </Container>

                <Tiles
                    onChange={({detail}) => setDurationGroup(detail.value)}
                    value={durationGroup}
                    items={_durationGroups}/>

                <ColumnLayout columns={2}>

                    {packages.filter(f => f.price.period > kvDurations[durationGroup].min && f.price.period <= kvDurations[durationGroup].max)
                        .map(pck =>
                            <PackageCard
                                onClick={() => {
                                    navigate(`/purchase-package/${pck.PackageID}`, {replace: false});
                                }}
                                name={pck.title}
                                description={`@Ksh ${pck.price.amount} for ${pck.price.quantity}`}
                            />
                        )}
                </ColumnLayout>
            </ColumnLayout>
        </Container>
    );
}
